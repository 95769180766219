import React from 'react';
import { useNavigate } from 'react-router-dom';
interface Props {
  crumbs: {
    id: number;
    page: string;
    link: string;
  }[];
}

const Breadcrumbs: React.FC<Props> = ({ crumbs }) => {
  const navigate =useNavigate();
  return (
    <div>
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          {crumbs.map((res, index) => (
            <React.Fragment key={"breadcrumb"+index}>
              {index === 0 ? (
                <li key={index}>
                  <div className="flex">
                    <a
                      href={res.link}
                      className="text-sm  text-gray-500 hover:text-gray-700"
                    >
                      {res.page}
                    </a>
                  </div>
                </li>
              ) : (
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      />
                    </svg>
                    <a
                      href={res.link}
                      aria-current="page"
                      className="ml-4 text-sm text-gray-500 hover:text-gray-700"
                    >
                      {res.page}
                    </a>
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
