import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import img from "../assets/images/WT_logo_black.png";
import icon from "../assets/images/jb-icon-transparent-bg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginApi, testTokenApi } from "../services/http-api-service";
import OtpInput from "react-otp-input";
import { TailSpin } from "react-loader-spinner";

const Login = () => {
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    otp_code: "",
  });
  const [checking, setChecking] = useState(true);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [otpcheck, setOtpcheck] = useState(true);
  const [checkEmail, setCheckEmail] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const validateEmail = (checkEmail: string) =>
    typeof checkEmail === "string" && checkEmail.includes("@");

  function login(e: any) {
    e.preventDefault();
    loginApi(userData)
      .then((response: any) => {
        if (JSON.stringify(response.status) === "200") {
          localStorage.setItem("localToken", response.data.access_token);
          localStorage.setItem("localUserId", response.data.user_id);

          toast.success("Logged In");
          navigate("/journey-brain/dashboard");
        }

        setChecking(true);
      })
      .catch((error: any) => {
        if (error.response.data.error === "otpfail") {
          setOtpcheck(false);
          setChecking(true);
          toast.success("OTP sent !");
        } else {
          toast.error(error.response.statusText);
        }
      });
  }

  useEffect(() => {
    setCheckEmail(userData.username);
    if (otpcheck) {
      if (userData.username && userData.password) {
        if (validateEmail(checkEmail)) {
          setChecking(false);
        } else {
          setChecking(true);
        }
      }
    } else if (!otpcheck) {
      if (userData.otp_code.length === 6) {
        setChecking(false);
      } else {
        setChecking(true);
      }
    }
  }, [userData]);

  function testExistingToken() {

    testTokenApi()
      .then((response: any) => {
        if (response.data.success === "Token is authorised") {
          navigate("/journey-brain/dashboard");
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  }
  useEffect(() => {
    setShowLoader(false);
    setShowLoader(false);
    return () => testExistingToken();
  }, []);

  const handleOtpChange = (otpdata: any) => {
    setOtp(otpdata);
    setUserData({ ...userData, otp_code: otpdata });
  };

  return (
    <div>
      <title>
        <link rel="JB icon" href={icon} />
        Journey Brain
      </title>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      <div className="h-screen min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
        {showLoader === true && (
          <div className="h-full grid place-content-center">
            <TailSpin color="#019393" height={100} width={100} />
          </div>
        )}
        {showLoader !== true && (
          <div className="m-auto max-w-md w-full space-y-8">
            <img src={img} alt="JB-logo" className="m-auto h-14 w-73" />
            {otpcheck ? (
              <>
                <h2 className="mt-6 text-center text-2xl font-bold">Sign in to your account</h2>
                {/* <p className="mt-2 text-center text-sm text-gray-600">
                  Or{" "}
                  <a href="/signup" className="font-light text-[#00B5B5] hover:text-[#00B5B5]">
                    Sign up for an account
                  </a>
                </p> */}
              </>
            ) : (
              <p className="mt-6 text-center text-2xl font-bold"> Enter One-Time-Password</p>
            )}
            <form className="mt-8 space-y-6" onSubmit={login}>
              <input type="hidden" name="remember" value="true" />
              <div>
                {otpcheck ? (
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label className="sr-only">Email address</label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        value={userData.username}
                        onChange={(e) =>
                          setUserData({ ...userData, username: e.target.value.toLowerCase() })
                        }
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#00B5B5] focus:border-[#00B5B5] focus:z-10 sm:text-sm"
                        placeholder="Email address"
                      />
                    </div>
                    <div>
                      <label className="sr-only">Password</label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={userData.password}
                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-[#00B5B5] focus:border-[#00B5B5] focus:z-10 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="grid justify-items-center">
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      className="mx-2 border-gray-300 rounded-t-md rounded-b-md border otp-box"
                    />
                  </div>
                )}
              </div>

              {otpcheck ? (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="rounded-b-lg text-[#00B5B5] accent-[#00B5B5] h-4 w-4 focus:ring-[#00B5B5] border-gray-300"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm cursor-default">
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm">
                    <a href="/forgot-password" className=" hover:text-gray cursor-pointer">
                      Forgot your password?
                    </a>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div>
                {!checking ? (
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#00B5B5] hover:border-gray cursor-pointer hover:text-black"
                    disabled={checking}
                  >
                    {otpcheck ? <>Sign in</> : <>Verify OTP</>}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white cursor-not-allowed bg-gray-300"
                    disabled={checking}
                  >
                    {otpcheck ? <>Sign in</> : <>Verify OTP</>}
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
