import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../assets/images/WT_logo_black.png";
import { signupApi, uploadSignupPic } from "../services/http-api-service";
import { ToastContainer, toast } from "react-toastify";
import icon from "../assets/images/jb-icon-transparent-bg.png";
import { TailSpin } from "react-loader-spinner";
import LinearProgress from "@mui/material/LinearProgress";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    tfa_enabled: false,
    profile_image: null,
    gender: "",
    pronouns: "",
    job_title: "",
    tfa_otp_code: null,
    social_linkedin: "",
    social_meta: "",
    social_twitter: "",
    social_instagram: "",
    social_other: "",
    profile_pic: "",
  });
  const [mandatoryFieldschecking, setMandatoryFieldsChecking] = useState(true);
  const [checkEmail, setCheckEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [urlCheck, seturlCheck] = useState({
    linkedIn: false,
    meta: false,
    instagram: false,
    twitter: false,
    other: false,
  });
  const validateEmail = (checkEmail: string) =>
    typeof checkEmail === "string" && checkEmail.includes("@");

  useEffect(() => {
    document.title = "Journey Brain Sign-Up";
  }, []);

  const urlPatternValidation = (URL: any) => {
    const regex = new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
    return regex.test(URL);
  };

  useEffect(() => {
    setCheckEmail(userData.email);
    if (
      userData.name &&
      userData.password &&
      userData.gender !== "" &&
      userData.password === confirmPassword
    ) {
      if (validateEmail(checkEmail)) {
        setMandatoryFieldsChecking(false);
      } else {
        setMandatoryFieldsChecking(true);
      }
    } else {
      setMandatoryFieldsChecking(true);
    }
  }, [userData.email, userData.gender, userData.name, userData.password, confirmPassword]);

  useEffect(() => {
    if (userData.social_linkedin) {
      setMandatoryFieldsChecking(!urlPatternValidation(userData.social_linkedin));
      seturlCheck({ ...urlCheck, linkedIn: !urlPatternValidation(userData.social_linkedin) });
    }
  }, [userData.social_linkedin]);

  useEffect(() => {
    if (userData.social_meta) {
      setMandatoryFieldsChecking(!urlPatternValidation(userData.social_meta));
      seturlCheck({ ...urlCheck, meta: !urlPatternValidation(userData.social_meta) });
    }
  }, [userData.social_meta]);

  useEffect(() => {
    if (userData.social_twitter) {
      setMandatoryFieldsChecking(!urlPatternValidation(userData.social_twitter));
      seturlCheck({ ...urlCheck, twitter: !urlPatternValidation(userData.social_twitter) });
    }
  }, [userData.social_twitter]);

  useEffect(() => {
    if (userData.social_instagram) {
      setMandatoryFieldsChecking(!urlPatternValidation(userData.social_instagram));
      seturlCheck({ ...urlCheck, instagram: !urlPatternValidation(userData.social_instagram) });
    }
  }, [userData.social_instagram]);

  function submitForm(e: any) {
    e.preventDefault();
    setShowLoader(true);
   signupApi(userData)
      .then((response) => {
        console.log("in responce");
        setShowLoader(false);
        toast.success(response.statusText);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((error) => {
        console.log(error.response.data.error.email[0]);
        setShowLoader(false);
        if(error.response.data.error){
          toast.error(error.response.data.error.email[0]);
        }else{
        toast.error(error.response.data.message);
        }
      });
  }

  const setNewImage = (e: any) => {
    const body = new FormData();
    body.append("profile_pic", e.target.files[0]);
    uploadSignupPic(body)
      .then((res: any) => {
        setUserData({
          ...userData,
          profile_image: res.data.profile_image,
        });
        userData.profile_image = res.data.profile_image;
        setUserData({
          ...userData,
          profile_pic: res.data.profile_pic,
        });

      })
      .catch((error: any) => {
        //
      });

  };

  const hiddenFileInput = useRef<any>();

  const handleImageChange = (event: any) => {
    event.preventDefault();
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <div>
      <title>
        <link rel="JB icon" href={icon} />
        Journey Brain
      </title>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      {showLoader === true && (
        <div className="h-screen w-screen grid place-content-center">
          <TailSpin color="#019393" height={80} width={80} />
        </div>
      )}
      <div className="h-screen overflow-y-auto flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
        <div className="m-auto max-w-md w-full space-y-8 pb-20">
          <div>
            <img src={img} alt="JB-logo" className="m-auto h-16 w-84" />
            <h2 className="mt-6 text-center text-2xl font-bold">Sign up for an account</h2>
          </div>
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={submitForm}>
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="pt-8">
                <div>
                  <h3 className="text-lg leading-6  text-gray-900">Your Information</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    You'll be able to edit all of this from your account area.
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label className="block text-sm text-gray-700">
                      <span className="text-[#ff0000]">*</span>
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={userData.name}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block h-10 w-full sm:text-sm border-gray-300 border rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      <span className="text-[#ff0000]">*</span>
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={userData.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full  sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      <span className="text-[#ff0000]">*</span>
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={userData.password}
                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full  sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      <span className="text-[#ff0000]">*</span>
                      Confirm your Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full  sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6 flex">
                    <input
                      id="T-FA"
                      name="T-FA"
                      type="checkbox"
                      onChange={() =>
                        setUserData({
                          ...userData,
                          tfa_enabled: !userData.tfa_enabled,
                        })
                      }
                      className="rounded-b-lg text-[#00B5B5] accent-jb_bg h-4 w-4 focus:ring-jb_bg border-gray-300"
                    />
                    <label htmlFor="T-FA" className="ml-2 block text-sm">
                      Two-Factor Authentication (optional)
                    </label>
                  </div>

                  <div className="sm:col-span-6 flex">
                    <label className="block text-sm  text-gray-700">Photo</label>
                    <div className="mt-1 flex items-center">
                      <span className=" w-12 rounded-full overflow-hidden bg-gray-100">
                        {userData.profile_pic && (
                          <img
                            alt="not found"
                            width={"250px"}
                            src={userData.profile_pic}
                          />
                        )}
                      </span>
                      <button
                        onClick={handleImageChange}
                        className="ml-5 pb-1 px-4 border border-transparent shadow-sm text-sm  rounded-md text-white bg-gray-300"
                      >
                        Change
                      </button>
                      <input
                        id="profilePic"
                        type="file"
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                        onChange={(e) => setNewImage(e)}
                        className="ml-5 bg-white py-2 w-full px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5]"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="block text-sm  text-gray-700">
                      <span className="text-[#ff0000]">*</span>
                      Gender
                    </label>
                    <div className="mt-1">
                      <select
                        id="gender"
                        name="gender"
                        value={userData.gender}
                        onChange={(e) => setUserData({ ...userData, gender: e.target.value })}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full px-2 sm:text-sm border-gray-300 h-10 border rounded-md"
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="not-disclosed">Prefer not to say</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="block text-sm  text-gray-700">Pronouns</label>
                    <div className="mt-1">
                      <select
                        id="pronouns"
                        name="pronouns"
                        value={userData.pronouns}
                        onChange={(e) => setUserData({ ...userData, pronouns: e.target.value })}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] block w-full px-2 sm:text-sm border-gray-300 h-10 border rounded-md"
                      >
                        <option>Select Pronouns</option>
                        <option value="he,him,his">he, him, his</option>
                        <option value="she,her,hers">she, her, hers</option>
                        <option value="them,they,theirs">them, they, theirs</option>
                        <option value="Prefer not to say">Prefer not to say</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">Job Title</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="job-title"
                        id="job-title"
                        value={userData.job_title}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            job_title: e.target.value,
                          })
                        }
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      LinkedIn Profile
                      {urlCheck.linkedIn && (
                        <span className="text-[#ff0000] px-4">Invalid URL</span>
                      )}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        value={userData.social_linkedin}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            social_linkedin: e.target.value,
                          });
                          if (!e.target.value) {
                            setMandatoryFieldsChecking(false);
                            seturlCheck({ ...urlCheck, linkedIn: false });
                          }
                        }}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      Meta/Facebook Profile
                      {urlCheck.meta && <span className="text-[#ff0000] px-4">Invalid URL</span>}
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        name="meta"
                        id="meta"
                        value={userData.social_meta}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            social_meta: e.target.value,
                          });
                          if (!e.target.value) {
                            setMandatoryFieldsChecking(false);
                            seturlCheck({ ...urlCheck, meta: false });
                          }
                        }}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      Twitter Profile
                      {urlCheck.twitter && <span className="text-[#ff0000] px-4">Invalid URL</span>}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="twitter"
                        id="twitter"
                        value={userData.social_twitter}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            social_twitter: e.target.value,
                          });
                          if (!e.target.value) {
                            setMandatoryFieldsChecking(false);
                            seturlCheck({ ...urlCheck, twitter: false });
                          }
                        }}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm  text-gray-700">
                      Instagram Profile
                      {urlCheck.instagram && (
                        <span className="text-[#ff0000] px-4">Invalid URL</span>
                      )}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="instagram"
                        id="instagram"
                        value={userData.social_instagram}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            social_instagram: e.target.value,
                          });
                          if (!e.target.value) {
                            setMandatoryFieldsChecking(false);
                            seturlCheck({ ...urlCheck, instagram: false });
                          }
                        }}
                        className="shadow-sm focus:ring-[#00B5B5] focus:border-[#00B5B5] px-2 block w-full sm:text-sm border-gray-300 h-10 border rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <div>
                  {mandatoryFieldschecking && userData.password !== confirmPassword ? (
                    <div className="text-[#ff0000] text-sm mr-3 ">
                      *Password & Confirm Password do not match !
                    </div>
                  ) : null}
                  {mandatoryFieldschecking &&
                  (!userData.email ||
                    !userData.gender ||
                    !userData.name ||
                    !userData.password ||
                    !confirmPassword) ? (
                    <div className="text-[#ff0000] text-sm mr-2 ">
                      *Please fill all the mandatory fields to continue
                    </div>
                  ) : null}
                </div>

                <button
                  type="button"
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="bg-white py-2 h-10 px-4 border border-gray-300 rounded-md shadow-sm text-sm  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5]"
                >
                  Cancel
                </button>
                {!mandatoryFieldschecking &&
                !urlCheck.linkedIn &&
                !urlCheck.instagram &&
                !urlCheck.meta &&
                !urlCheck.twitter ? (
                  <button
                    type="submit"
                    disabled={mandatoryFieldschecking}
                    className="ml-3 pb-1 px-4 h-10 border border-transparent cursor-pointer shadow-sm text-sm  rounded-md text-white bg-[#00B5B5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5]"
                  >
                    Sign up
                  </button>
                ) : (
                  <>
                    <button
                      type="submit"
                      disabled={mandatoryFieldschecking}
                      className="ml-3 pb-1 px-4 h-10 border cursor-not-allowed border-transparent shadow-sm text-xs md:text-sm  rounded-md text-white bg-gray-300"
                    >
                      Sign up
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
