import axios from "axios";
import { logout } from "./services/http-api-service";

const token: any = localStorage.getItem("localToken");
const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use((request) => {
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      (window.location.pathname === "/" ||
        window.location.pathname === "/login" ||
        window.location.pathname === "/signup" ||
        window.location.pathname === "/forgot-password")
    ) {
    } else if (error.response.status === 401 && error.response.data.error !== "otpfail") {
      logout();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
