import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ReactPlayer from "react-player";
import { useScreenshot, createFileName } from "use-react-screenshot";

import SimpleDialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";

import dayjs from "dayjs";

import { BubbleChart } from "./charts/bubble-chart";
import Breadcrumbs from "./breadcrumbs";
import SendSurvey from "./send-survey";
import FlowFoundationEdit from "./flow-foundation-edit";
import FlowFoundationInvite from "./flow-foundation-invite";

import {
  getAssessmentDetailsApi,
  getAssessmentBubbleChartAverageApi,
  downloadAssessmentApi,
} from "../services/http-api-service";

import briefingImage from "../assets/images/wm_ff_explanation.png";
import graphimg from "../assets/images/bubble_chart_left_blank.png";

interface IBreadcrumbList {
  id: number;
  page: string;
  link: string;
}

interface BubbleChartDataType {
  x: number;
  y: number;
  z: number;
  markerColor: `#${string | number}`;
}

interface AvgLineDataType {
  x: number;
  y: number;
  z: number;
  markerColor: `#${string | number}`;
  markerType: string;
  markerThickness: number;
}

const FlowFoundationView = () => {
  const location = useLocation();
  const { state } = useLocation() as any;
  const { routeState, assessmentData } = state;
  const [breadcrumbsList, setBreadcrumbsList] = useState<IBreadcrumbList[]>([]);
  const [stateData, setStateData] = useState(state);
  const navigate = useNavigate();

  useEffect(() => {
    if (state == null) {
      setStateData({ state: { routeState: routeState } });
    }
    setBreadcrumbsList([
      { id: 1, page: "Dashboard", link: "/journey-brain/dashboard" },
      {
        id: 2,
        page: "Flow Foundations",
        link: "/journey-brain/flow-foundation",
      },
      { id: 3, page: "My Assessment", link: "#" },
    ]);
  }, [state]);

  return (
    <div className="mx-5 mt-5">
      <nav className="sm:hidden" aria-label="Back">
        <span
          onClick={() =>
            navigate("/journey-brain/flow-foundation", {
              state: state,
            })
          }
          className="flex items-center text-sm  text-gray-500 hover:text-gray-700 cursor-pointer"
        >
          <svg
            className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" />
          </svg>
          Back
        </span>
      </nav>
      <Breadcrumbs crumbs={breadcrumbsList} />
      {location.pathname !== "/journey-brain/flow-foundation/new" && <FlowFoundationInProgress />}
      {location.pathname === "/journey-brain/flow-foundation/new" && <FlowFoundationNew />}
    </div>
  );
};

function FlowFoundationNew() {
  const { routeState } = useLocation() as any;
  const { state } = useLocation() as any;

  const [openEdit, setOpenEdit] = useState(false);
  const [stateData, setStateData] = useState(state);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openSendSurveyModal, setOpenSendSurveyModal] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState<Record<string, any> | null>(null);

  const handleClose = () => {
    setOpenInviteModal(false);
    setOpenSendSurveyModal(false);
  };

  const setAssessment = (data: Record<string, any>) => setAssessmentDetails(data);

  const getAssessmentData = () => {
    getAssessmentDetailsApi(state.assessmentData.id)
      .then((res: any) => {
        let data = res.data.data;
        setAssessmentDetails(data);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    // return () => getAssessmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAssessmentDetailsApi(state.assessmentData.id)
      .then(res => setAssessmentDetails(res.data.data));
  }, [state.assessmentData.id]);

  useEffect(() => {
    if (!state) setStateData({ state: { routeState } });
  }, [routeState, state]);

  return (
    <div>
      <div className="my-10 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 cursor-pointer">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <a
              onClick={() => {
                setOpenInviteModal(true);
              }}
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-[#00B5B5] text-sm text-white hover:bg-gray-50 focus:z-10 hover:text-black focus:outline-none focus:ring-1 focus:ring-indigo-500 border focus:border-indigo-500"
            >
              Invite Team Members
              <svg
                className="svg-inline--fa fa-user-plus ml-2 h-5 w-5"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user-plus"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z"
                ></path>
              </svg>
            </a>
            <a
              onClick={() => {
                setOpenSendSurveyModal(true);
              }}
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm   text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md"
            >
              Send Survey
            </a>
          </span>
        </div>
        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          <button
            onClick={() => setOpenEdit(true)}
            className="md:ml-3 ml-0 cursor-pointer inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm   text-white bg-[#00B5B5] hover:border-gray hover:bg-white border hover:text-black hover:ring-[#00B5B5]"
          >
            Edit This Flow Foundation
          </button>
        </div>
      </div>
      <SimpleDialog
        open={openInviteModal}
        onClose={handleClose}
        className="invite-dialogue-custom-container w-[100vw] md:w-full overflow-hidden"
      >
        <FlowFoundationInvite
          handleClose={handleClose}
          id={state?.assessmentData?.id}
          account_id={state?.assessmentData?.account_id}
        />
      </SimpleDialog>
      <SimpleDialog
        open={openSendSurveyModal}
        onClose={handleClose}
        className="send_survey-dialogue-custom-container"
      >
        <SendSurvey handleClose={handleClose} id={state?.assessmentData?.id} />
      </SimpleDialog>
      <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
        <div className="w-[80vw]  sm:w-[60vw] md:w-[50vw] lg:w-[30vw]">
          <div className="absolute -top-5 mr-2 sm:left-10 sm:right-96  flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
            <a
              onClick={() => setOpenEdit(false)}
              className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span className="sr-only cursor-pointer">Close panel</span>
              <svg
                className="h-6 w-6"
                x-description="Heroicon name: outline/x"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </a>
          </div>
          <FlowFoundationEdit setAssessment={setAssessment} />
        </div>
      </Drawer>
      <div className="mt-10 lg:flex ">
        <div className="w-[80vw]  sm:w-[50vw] md:w-[40vw] ">
          {assessmentDetails?.temp_url ? (
            <ReactPlayer width="100%" url={assessmentDetails?.temp_url} controls={true} />
          ) : (
            <ReactPlayer
                width="100%"
                url="/video-missing.mp4"
                controls={true}
              />
          )}
        </div>
        <div className=" mx-5 text-left">
          <h1>
            <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl">
              <span className="text-gray-900">{assessmentDetails?.name}</span>
            </span>
          </h1>
          <p
            dangerouslySetInnerHTML={{ __html: assessmentDetails?.description }}
            className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
          ></p>
        </div>
      </div>
    </div>
  );
}

function FlowFoundationInProgress() {
  const navigate = useNavigate();

  const [image, capture] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const { state } = useLocation() as any;
  const [isDownloadInProgress, setDownloadProgressState] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState<Record<string, any> | null>(null);
  const [assessmentChartDetails, setAssessmentChartDetails] = useState([]);
  const [bubbleChartArr, setBubbleChartArr] = useState([]);

  const [participantCount, setParticipantCount] = useState({
    completedCount: 0,
    totalCount: 0,
  });

  const handleClose = () => setOpenModal(false);

  const setAssessment = (data: Record<string, any>) => setAssessmentDetails(data);

  const getAssessmentData = () => {
    getAssessmentDetailsApi(state.assessmentData.id)
      .then((res: any) => {
        let data = res.data.data;
        setAssessmentDetails(data);
      })
      .catch((err: any) => console.log(err));
  };

  const takeScreenShot = (elementId: string, { name = elementId, extension = "png" } = {}) => {
    const targetElem: HTMLElement = document.querySelector(`#${elementId}`) as HTMLElement;
    const branding: HTMLElement = targetElem.querySelector(
      ".assessment-chartCopyright"
    ) as HTMLDivElement;

    branding.classList.remove("hidden");

    capture(targetElem).then((imageData: any) => {
      let link = document.createElement("a");
      link.href = imageData;
      link.download = createFileName(extension, name);
      link.click();

      branding.classList.add("hidden");
    });
  };

  const bubbleChartDataMaker = (data: Record<string, any>[]): BubbleChartDataType[] => {

    // work out how many participant averages are in each band
    let bubbleChartBands = {
      '0_10': 0,
      '11_20': 0,
      '21_30': 0,
      '31_40': 0,
      '41_50': 0,
      '51_60': 0,
      '61_70': 0,
      '71_80': 0,
      '81_90': 0,
      '91_100': 0,
      '101_110': 0
    };
    data.forEach((item) => {

      // calculate item.answer as percentage out of 10
      let answer = (item.answer / 10) * 100;

      if (answer < 11) {
        bubbleChartBands['0_10']++;
      } else if (answer < 21) {
        bubbleChartBands['11_20']++;
      } else if (answer < 31) {
        bubbleChartBands['21_30']++;
      } else if (answer < 41) {
        bubbleChartBands['31_40']++;
      } else if (answer < 51) {
        bubbleChartBands['41_50']++;
      } else if (answer < 61) {
        bubbleChartBands['51_60']++;
      } else if (answer < 71) {
        bubbleChartBands['61_70']++;
      } else if (answer < 81) {
        bubbleChartBands['71_80']++;
      } else if (answer < 91) {
        bubbleChartBands['81_90']++;
      } else if (answer < 101) {
        bubbleChartBands['91_100']++;
      } else {
        bubbleChartBands['101_110']++;
      }
    });
    
    // now add the number of answers in each band as a bubble on the chart
    
    const bubbleChartData: BubbleChartDataType[] = [];
    
    for (const [band, answer] of Object.entries(bubbleChartBands)) {
      
      if (!answer) continue;
      
      let xPos = 0;
      if (band == '0_10') {
        xPos = 5;
      } else if (band == '11_20') {
        xPos = 15;
      } else if (band == '21_30') {
        xPos = 25;
      } else if (band == '31_40') {
        xPos = 35;
      } else if (band == '41_50') {
        xPos = 45;
      } else if (band == '51_60') {
        xPos = 55;
      } else if (band == '61_70') {
        xPos = 65;
      } else if (band == '71_80') {
        xPos = 75;
      } else if (band == '81_90') {
        xPos = 85;
      } else if (band == '91_100') {
        xPos = 95;
      } else {
        xPos = 105;
      }

      bubbleChartData.push({
        x: xPos,
        y: 18,
        z: answer,
        markerColor: (() => {
          if (xPos < 50) return "#b02417";
          if (xPos >= 50 && xPos < 71) return "#f6c042";
          if (xPos >= 71) return "#4eab5a";
          else return "#b02417";
        })(),
      });
    }

    return bubbleChartData;
  };

  const bubbleChartAvgGetter = (data: Record<string, any>[]): AvgLineDataType[] => {
    console.log('data: ', data);
    
    const bubbleChartData: BubbleChartDataType[] = bubbleChartDataMaker(data);
    const bubbleChartAvg: AvgLineDataType[] = [
      { x: 0, y: 25, z: 0, markerColor: "#54554d", markerType: "circle", markerThickness: 20 },
      { x: -1, y: 25, z: 0, markerColor: "#d9aca6", markerType: "circle", markerThickness: 1 },
    ];

    console.log('bubbleChartData: ', bubbleChartData);

    let totalAsPercentage: number = data.reduce(
      (total, chartData) => (total += ((chartData.answer/10)*100)), 0
    );
    
    if (bubbleChartData.length > 0) {
      
      let average = totalAsPercentage / data.length;
      let xPos = 0;
      if (average < 10) {
        xPos = 5;
      } else if (average < 21) {
        xPos = 15;
      } else if (average < 31) {
        xPos = 25;
      } else if (average < 41) {
        xPos = 35;
      } else if (average < 51) {
        xPos = 45;
      } else if (average < 61) {
        xPos = 55;
      } else if (average < 71) {
        xPos = 65;
      } else if (average < 81) {
        xPos = 75;
      } else if (average < 91) {
        xPos = 85;
      } else if (average < 101) {
        xPos = 95;
      } else {
        xPos = 105;
      }
      
      bubbleChartAvg[0].x = xPos;
      bubbleChartAvg[0].z = average;
    } else {
      bubbleChartAvg[0].x = 0;
      bubbleChartAvg[0].z = 0;
      bubbleChartAvg[0].markerThickness = 0;
      bubbleChartAvg[1].x = 0;
      bubbleChartAvg[0].z = 0;
      bubbleChartAvg[1].markerThickness = 0;
      bubbleChartAvg[1].markerColor = "#cc9d93";
    }

    console.log('bubbleChartAvg: ', bubbleChartAvg);

    return bubbleChartAvg;
  };

  const getAssessmentBubbleChartData = () => {
    getAssessmentBubbleChartAverageApi(state.assessmentData.id)
      .then((res: any) => {
        let data = res.data.data;
        setAssessmentChartDetails(data);
        setBubbleChartArr(data.assessment_types);
      })
      .catch((err: any) => console.log(err));
  };

  const onDownloadAssessmentReport = async () => {
    if (!assessmentDetails) {
      return;
    }
    
    const reportStatsAsync = bubbleChartArr.map(async ({ id, name, answers }: any) => {
      //let averageTotal = 0;
      let averageTotal: number = 0;
      answers.forEach((item) => {
        averageTotal += ((parseInt(item.answer)/10)*100);
      });

      const rangeValue = bubbleChartDataMaker(answers).map(({ z }) => (z/10)*100);
      const min = Math.min(...rangeValue);
      const max = Math.max(...rangeValue);

      return {
        id,
        name,
        chart: await capture(document.querySelector(`.js-chart-wrapper-${id}`) as HTMLElement),
        diff: max - min,
        //avg: bubbleChartAvgGetter(answers)[0].z,
        avg: (averageTotal / answers.length).toFixed(0)
      };
    });

    const reportStats: {
      id: number | string;
      name: string;
      chart: string;
      diff: number;
      avg: any;
    }[] = [];

    for await (const stats of reportStatsAsync) {
      reportStats.push(stats);
    }

    setDownloadProgressState(true);

    const response = await downloadAssessmentApi(assessmentDetails.id, {
      reportStats,
      completedCount: participantCount.completedCount,
      collectedDate: assessmentDetails.updated_at,
    });

    if (response.ok) {
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "ff-report.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    }

    setDownloadProgressState(false);
  };

  useEffect(() => {
    let completedCount = assessmentDetails?.participants.filter((participant: any) => {
      return participant.assessment_completed;
    }).length;
    let totalCount = assessmentDetails?.participants.length;

    setParticipantCount({
      completedCount: completedCount,
      totalCount: totalCount,
    });
  }, [assessmentDetails]);

  // useEffect(() => {
  //   return () => getAssessmentData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // return () => getAssessmentBubbleChartData();
    
    getAssessmentDetailsApi(state.assessmentData.id)
      .then(res => setAssessmentDetails(res.data.data));
      
    getAssessmentBubbleChartAverageApi(state.assessmentData.id)
      .then(res => {
        setAssessmentChartDetails(res.data.data);
        setBubbleChartArr(res.data.data.assessment_types);
      });
  }, [state.assessmentData.id]);

  return (
    <div className="pb-10">
      <div className="mt-10 lg:grid lg:grid-cols-12 lg:gap-8">
        <div className=" grid place-items-center mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-4 lg:flex lg:items-center">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm   bg-yellow-100 text-yellow-800 absolute z-10 -top-4 -right-4 md:-right-8">
            {state.assessmentData.status === "in_progress" && <>In Progress</>}
            {state.assessmentData.status === "invites_sent" && <>Invites Sent</>}
            {state.assessmentData.status === "closed" && <>Closed</>}
            {state.assessmentData.status === "complete" && <>Completed</>}
          </span>
          <div className="w-[90vw]  sm:w-[60vw] md:w-[50vw] ">
            {assessmentDetails?.temp_url ? (
              <ReactPlayer
                width="100%"
                height="50%"
                url={assessmentDetails?.temp_url}
                controls={true}
              />
            ) : ( 
              <ReactPlayer
                width="100%"
                height="50%"
                url="/video-missing.mp4"
                controls={true}
              />
            )}
          </div>
        </div>
        <div className="sm:text-center md:mx-auto lg:col-span-8 lg:text-left">
          <div className="mb-5 md:flex md:items-center md:justify-between grid place-items-center">
            <div className="py-2 px-1  w-full bg-white ml-0.5 sm:ml-0 sm:rounded-md font-bold border text-center border-gray xl:text-xm xl:text-md">
              <p>
                {participantCount.completedCount} of {participantCount.totalCount} invitees have
                completed
              </p>
              <p>Deadline: {dayjs(assessmentDetails?.due_date).format("DD/MM/YYYY")}</p>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-[#00B5B5] text-sm  text-white hover:bg-gray-50 focus:z-10 hover:text-black focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  onClick={onDownloadAssessmentReport}
                  disabled={isDownloadInProgress}
                >
                  Download PDF
                  <svg
                    className="svg-inline--fa fa-file-pdf ml-2 h-4 w-4"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="file-pdf"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M88 304H80V256H88C101.3 256 112 266.7 112 280C112 293.3 101.3 304 88 304zM192 256H200C208.8 256 216 263.2 216 272V336C216 344.8 208.8 352 200 352H192V256zM224 0V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64C0 28.65 28.65 0 64 0H224zM64 224C55.16 224 48 231.2 48 240V368C48 376.8 55.16 384 64 384C72.84 384 80 376.8 80 368V336H88C118.9 336 144 310.9 144 280C144 249.1 118.9 224 88 224H64zM160 368C160 376.8 167.2 384 176 384H200C226.5 384 248 362.5 248 336V272C248 245.5 226.5 224 200 224H176C167.2 224 160 231.2 160 240V368zM288 224C279.2 224 272 231.2 272 240V368C272 376.8 279.2 384 288 384C296.8 384 304 376.8 304 368V320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H304V256H336C344.8 256 352 248.8 352 240C352 231.2 344.8 224 336 224H288zM256 0L384 128H256V0z"
                    ></path>
                  </svg>
                  <i className="fa-solid fa-file-pdf ml-2"></i>
                </button>
                <button
                  onClick={() => {
                    navigate("/journey-brain/flow-foundation/progress-data", {
                      state: {
                        ...state,
                        assessmentData: assessmentDetails,
                      },
                    });
                  }}
                  className="-ml-px relative cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm  text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 "
                >
                  View Survey Data
                </button>

                <button
                  onClick={() => setOpenEdit(true)}
                  className="-ml-px relative cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md"
                >
                  Edit Flow Foundation
                </button>
              </span>
            </div>
          </div>
          <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
            <div className="w-[80vw]  sm:w-[60vw] lg:w-[30vw]">
              <div className="absolute -top-5 mr-2 sm:left-10 sm:right-96 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                <a
                  onClick={() => setOpenEdit(false)}
                  className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <span className="sr-only cursor-pointer">Close panel</span>
                  <svg
                    className="h-6 w-6"
                    x-description="Heroicon name: outline/x"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </a>
              </div>
              <FlowFoundationEdit setAssessment={setAssessment} />
            </div>
          </Drawer>
          <SimpleDialog
            open={openModal}
            onClose={handleClose}
            className="dialogue-custom-container"
          >
            <div className="grid place-items-center">
              <img src={briefingImage} alt="JB-logo" className="m-auto  " />
              <button
                onClick={() => {
                  setOpenModal(false);
                }}
                className="relative inline-flex  m-5 items-center w-11/12 text-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-jb_bg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </SimpleDialog>
          <h1>
            <span className="mt-1 block text-4xl  font-bold sm:text-5xl xl:text-4xl 2xl:text-5xl">
              <span className="text-gray-900">{assessmentDetails?.name}</span>
            </span>
          </h1>
          <p
            dangerouslySetInnerHTML={{ __html: assessmentDetails?.description }}
            className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
          ></p>
          <button
            onClick={() => {
              setOpenModal(true);
            }}
            className="relative inline-flex  mt-5 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#00B5B5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            What am I looking at?
          </button>
        </div>
      </div>

      <div className="mt-10 bg-[#00B5B5] rounded-lg">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-white">
                <svg
                  className="h-6 w-6 text-black"
                  x-description="Heroicon name: outline/speakerphone"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  ></path>
                </svg>
              </span>
              <p className="ml-3  text-white truncate">
                <span>
                  You can view the results of the survey whilst your Flow Foundation is still in
                  progress. Once it's complete it will show "completed" above.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {bubbleChartArr.map((bubbleChartData: any) => (
        <div
          className="mt-10 bg-white overflow-hidden sm:rounded-lg sm:shadow"
          key={bubbleChartData.id}
        >
          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
              <div className="assessment__data-heading">
                <h3 className="text-lg leading-6 text-gray-900">{bubbleChartData.name}</h3>
                <p className="m-0 text-sm text-gray-500" dangerouslySetInnerHTML={{__html: bubbleChartData.description}}></p>
              </div>
              <div className="flex flex-shrink-0 gap-2">
                <Link
                  to={`/journey-brain/flow-foundation/in-progress-view-chart/${state.assessmentData.id}/${bubbleChartData.id}`}
                  className="relative
                  inline-flex
                  items-center
                  px-4 py-2
                  border
                  border-transparent
                  shadow-sm
                  text-sm rounded-md
                  text-white
                  bg-[#00B5B5]
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500"
                >
                  View
                </Link>

                <button
                  className="relative
                  inline-flex
                  items-center
                  px-4 py-2
                  border
                  border-transparent
                  shadow-sm
                  text-sm rounded-md
                  text-white
                  bg-[#00B5B5]
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500"
                  onClick={() => takeScreenShot(`assessment-chart-${bubbleChartData.id}`)}
                >
                  Download
                  <span className="icon flex h-[20px] w-[20px] ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      style={{
                        strokeWidth: 2,
                      }}
                    >
                      <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div
            className="assessment-chart
            relative flex
            bg-white
            border-b
            border-gray-200"
            id={`assessment-chart-${bubbleChartData.id}`}
          >
            <div
              style={{
                width: "197px",
                height: "156px",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url("${graphimg}")`,
              }}
            >
              <h2
                style={{
                  paddingLeft: "5%",
                  paddingTop: "43%",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
                className="relative"
              >
                {bubbleChartData.name.split(":")[0]}
                {bubbleChartData.name.split(":")[0] == "F1" && 
                  <img src="/images/JB-icons/bubble_chart_icon_F1.png" className="absolute h-[50px] right-0 -bottom-[15px]" />
                }
                {bubbleChartData.name.split(":")[0] == "F2" && 
                  <img src="/images/JB-icons/bubble_chart_icon_F2.png" className="absolute h-[50px] right-0 -bottom-[15px]" />
                }
                {bubbleChartData.name.split(":")[0] == "F3" && 
                  <img src="/images/JB-icons/bubble_chart_icon_F3.png" className="absolute h-[50px] right-0 -bottom-[15px]" />
                }
                {bubbleChartData.name.split(":")[0] == "F4" && 
                  <img src="/images/JB-icons/bubble_chart_icon_F4.png" className="absolute h-[50px] right-0 -bottom-[15px]" />
                }
              </h2>
            </div>
            <div
              style={{
                width: "calc(100% - 197px)",
                height: "156px",
              }}
              className={`js-chart-wrapper-${bubbleChartData.id}`}
            >
              <BubbleChart
                bubbleData={bubbleChartDataMaker(bubbleChartData.answers)}
                avgLineData={bubbleChartAvgGetter(bubbleChartData.answers)}
              />
            </div>

            <span className="assessment-chartCopyright hidden">&copy; Copyright Journey Brain</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FlowFoundationView;
