import React, { useEffect, useRef, useState,  Component  } from "react";
import {CanvasJSChart} from '../../assets/bubble-chart-custom/canvasjs-react-charts';
import graphimg from "../../assets/images/bubble_chart_single_blank.jpg";
import { url } from "inspector";

interface BubbleChartProps {
	bubbleData: any;
	avgLineData: any;
	optionalArgument?: string;
  }
  
  interface BubbleChartState{
	something: number;
  }
class BubbleChart extends Component<BubbleChartProps, BubbleChartState>  {
	constructor(props: BubbleChartProps){
		super(props);
	
		this.state = {
		  something: 23
		};
	  }
	chart: any;
	//CanvasJSChart.addColorSet("greenShades", ["#b02417", "#f6c042", "#4eab5a"]);
	render() {
		
		const options = {
			animationEnabled: true,
			colorSet: "greenShades",
			exportEnabled: false,
			//backgroundColor: "#d9aca6",
			backgroundColor: "transparent",
			fontColor:"FFFFFF",
			color:"FFFFFF",
			dataPointMaxWidth: 10,
			theme: "light2", // "light1", "light2", "dark1", "dark2"
			axisX2: {
				gridColor: "#ffdcd9",
				gridThickness: 2,
				lineColor: "white",
				interval: 10,
				titleFontSize: 14,
                includeZero: true,
				//labelFontColor: "#ffffff",
				titleFontColor: "#ffffff",
                tickLength: 10,
				tickColor: "transparent",
                tickThickness: 0,
				//indexLabelPlacement: "auto",
				maximum: 102,
		        minimum: 0,
				margin: 5,
				labelFontSize: 12,
				
			},
			axisY2: {
				title: "",
				lineThickness: 0,
				gridThickness: 0,
				tickLength: 0,
				titleFontSize: 14,
                includeZero: true,  
				//labelFontColor: "#ffffff",  
				titleFontColor: "#ffffff",     
				labelFormatter: function(e:any) {
					return "";
				},
			},
      
			data: [{
				type: "bubble",
				indexLabel: "{z}",
				indexLabelFontColor: "#FFFFFF",
				indexLabelFontSize: 12,
				axisXType: "secondary",
				axisYType: "secondary",
				toolTipContent: "<b>{z}</b> participants with this average",
				dataPoints: this.props.bubbleData
			},
			{
				type: "line",
				showInLegend: false,
				axisXType: "secondary",
		        axisYType: "secondary",
				lineThickness: 3,
				lineColor: "#54554d",
				markerSize: 15,
				dataPoints: this.props.avgLineData
			},
		]
		};

		//Styling Chart Container
		const containerProps = {
			width: "100%",
			height: "156px",
			backgroundRepeat: "no-repeat",
			backgroundSize:"100% 100%",
			backgroundImage: `url("${graphimg}")`
		  };
	
	

		return (
		<div>
	
			<CanvasJSChart options = {options} 
			containerProps={containerProps}
				 onRef={(ref: any) => this.chart = ref} 
			/>
			
			
		</div>
		);
		
	}

}

export {
	BubbleChart
  };
