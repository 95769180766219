import axiosInstance from "../http-common";

export function loginApi(data: object) {
  return axiosInstance.post("/token", data);
}
export function signupApi(data: object) {
  return axiosInstance.post("/register", data);
}

export function forgotpasswordApi(email: object) {
  return axiosInstance.post("/password/email", email);
}

export function testTokenApi() {
  return axiosInstance.get("/token/test", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function appCategoryListApi() {
  return axiosInstance.get("/application-categories", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function getApplicationDetailsById(applicationId: number) {
  return axiosInstance.get(`/applications/${applicationId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function getCourseApi(courseId = 1) {
  return axiosInstance.get(`/courses/${courseId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function getLessonDetailsApi(id: number) {
  return axiosInstance.get(`/lessons/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function teamMembersListApi() {
  return axiosInstance.get("/team-members?limit=50", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function addNewMembersApi(newMembers: any) {
  return axiosInstance.post("/team-members", newMembers, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function participantsListApi(assessment_id: any) {
  return axiosInstance.get("/participants?limit=50&assessment_id="+assessment_id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function createParticipantsApi(data: any) {
  return axiosInstance.post("/participants", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function removeParticipantsApi(participant_id: any) {
  return axiosInstance.delete("/participants/"+participant_id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function inviteTeamMembersApi(participant_ids: any,assessment_id:any) {
  return axiosInstance.post("/participants/invite", {participant_ids,assessment_id}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function sendSurveyApi(data: any) {
  return axiosInstance.post("/participants/survey", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function getUserDetailsApi() {
  return axiosInstance.get(`/users/${localStorage.getItem("localUserId")}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function saveUserDetailsApi(data: any) {
  return axiosInstance.patch(`/users/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

// export function getAccountSettingsUserDetailsApi() {
//   return axiosInstance.get(`/accounts/${localStorage.getItem("localUserId")}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("localToken")}`,
//     },
//   });
// }

// export function saveAccountSettingsApi(data: any) {
//   return axiosInstance.patch(`/accounts/${data.id}`, data, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("localToken")}`,
//     },
//   });
// }

export function getQuestionsApi(token: string) {
  return axiosInstance.get(`/assessment-questions/assessment/${token}`);
}

export function saveAnswersApi(answerData: any) {
  return axiosInstance.post("/assessment-answers", answerData);
}

export function listOfAssessmentsApi(appId: string) {
  return axiosInstance.get(`applications/${appId}/assessments?limit=50`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function createNewFlowApi(newFlowDetails: any) {
  return axiosInstance.post("/assessments", newFlowDetails, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function getAssessmentDetailsApi(id: number) {
  return axiosInstance.get(`/assessments/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function getAssessmentCSVdataApi(id: number) {
  return axiosInstance.get(`/assessments/export/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function saveAssessmentDetailsApi(data: any) {
  return axiosInstance.patch(`/assessments/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}
export function restartAssessmentApi(id: number) {
  return axiosInstance.post(
    `/assessments/restart/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("localToken")}`,
      },
    }
  );
}
export function closeAssessmentApi(id: number) {
  return axiosInstance.patch(
    `/assessments/close/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("localToken")}`,
      },
    }
  );
}

export function registerNewInterestApi(applicationId: any) {
  let data = {
    application_id: applicationId,
  };
  return axiosInstance.post("/eois", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function uploadProfilePic(body: any) {
  return axiosInstance.post("/upload-profile-pic", body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function getProfilePic() {
  return axiosInstance.get("/get-profile-pic", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function upload(body: Record<string, any>) {
  return axiosInstance.post("/file", body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
}

export function logout() {
  localStorage.removeItem("localToken");
  localStorage.removeItem("localUserId");
  window.location.href = "/";
};

export function getAssessmentBubbleChartAverageApi(id:number) {
  return axiosInstance.get(`/assessments/data/average/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
};

export function getAssessmentBubbleChartApi(id:number) {
  return axiosInstance.get(`/assessments/data/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("localToken")}`,
    },
  });
};

export const downloadAssessmentApi = (id: number, data: any) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/assessments/download/${id}`,
    {
      method: 'post',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("localToken")}`,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  );

  export function uploadSignupPic(body: any) {
    return axiosInstance.post("/upload-signup-pic", body, {});
  }
