import React, { createContext, useContext } from "react";

const UserContext = createContext<any>(null);

const UserProvider = ({ children }: Record<any, JSX.Element>) => {
  const [profileImage, setProfileImage] = React.useState<string>("");

  const addImageURL = (url: string) => {
    setProfileImage(url || "");
  };

  return (
    <UserContext.Provider
      value={{
        profileImage,
        addImageURL,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
export default UserProvider;
