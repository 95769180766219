import { useEffect, useRef, useState } from "react";
import {
  inviteTeamMembersApi,
  getAssessmentDetailsApi,
  teamMembersListApi,
  addNewMembersApi,
  participantsListApi,
  createParticipantsApi,
  removeParticipantsApi,
} from "../services/http-api-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { usePapaParse } from "react-papaparse";
import { useLocation, useNavigate } from "react-router-dom";

interface propsInterface {
  handleClose: any;
  id: number;
  account_id: number;
}
const FlowFoundationInvite: React.FC<propsInterface> = (props) => {
  interface listOfAddedMembers {
    assessment_id: number;
    participant_ids: number[];
  }

  const [membersListArray, setMembersListArray] = useState<any>();
  const [addedMembersListArray, setAddedMembersListArray] =
    useState<listOfAddedMembers>({
      assessment_id: props.id,
      participant_ids: [],
    });
  const [newMemberData, setNewMemberData] = useState({
    name: "",
    email: ""
  });
  const navigate = useNavigate();
  const { routeState } = useLocation() as any;
  const [showLoader, setShowLoader] = useState(true);
  const [newMembersArray, setNewMembersArray] = useState<any>([]);
  const state = useLocation() as any;
  useEffect(() => {
    return () => teamMembersData();
  }, []);

  const [stateData, setStateData] = useState(state);

  useEffect(() => {
    if (state == null) {
      setStateData({ state: { routeState: routeState } });
    }
     
  });

  const teamMembersData = () => {
    teamMembersListApi()
      .then((response: any) => {
        
        let membersList = response.data.data;
        
        // check list of participants and see which ones are already
        // added
        participantsListApi(props.id)
          .then((response: any) => {
            let participantsList = response.data.data;
            
            for (let member of membersList) {
              for (let participant of participantsList) {
                if (member.id == participant.team_member_id) {
                  member.isAdded = true;
                  member.participant_id = participant.id;
                  break;
                }
              }
            }
            
            setMembersListArray(membersList);
            setShowLoader(false);
          });
      })
      .catch((error: any) => {
        setShowLoader(false);
        toast.error(error.response.data.error);
      });
  };
  
  // add a team member as a participant
  const addedMembers = (memberId: number) => {
    let memArr = [...addedMembersListArray.participant_ids];
    memArr.push(memberId);
    setAddedMembersListArray({
      ...addedMembersListArray,
      participant_ids: memArr,
    });
    let memberList: any = [...membersListArray];

    for (let d of memberList) {
      if (d.id === memberId) {
        d.isAdded = true;
        
        // add this member as a participant
        let dataToCreate = { assessment_id: props.id, team_member_id: d.id };
        createParticipantsApi(dataToCreate).then((response: any) => {
          d.participant_id = response.data.id;
        inviteTeamMembersApi( [d.participant_id],props.id )
        });
      }
    }
    setMembersListArray(memberList);
  };

  // remove a team member as a participant
  const removeMember = (id: number) => {
    let arr = [...addedMembersListArray.participant_ids];
    let filterArr = arr.filter((d) => {
      return d !== id;
    });
    setAddedMembersListArray({
      ...addedMembersListArray,
      participant_ids: filterArr,
    });
    let memberList: any = [...membersListArray];
    for (let d of memberList) {
      if (d.id === id) {
        d.isAdded = false;
        
        // add this member as a participant
        removeParticipantsApi(d.participant_id).then((response: any) => {
          d.participant_id = null;
        });
      }
    }
    setMembersListArray(memberList);
  };

  const createParticipantsAndInvite = () => {
    let dataToCreate = {};
    let noOfMembers = 0;
    let counter = 0;
    let newparticipantId: any = [];
    let teamMemberId = [...addedMembersListArray.participant_ids];
    for (let d of teamMemberId) {
      dataToCreate = { assessment_id: props.id, team_member_id: d };
      noOfMembers = noOfMembers + 1;
      createParticipantsApi(dataToCreate)
        .then((response: any) => {
          newparticipantId.push(response.data.id);
          counter = counter + 1;
          if (counter === noOfMembers) {
            //toast.success(response.data.message);
            let newMembersArray = {
              ...addedMembersListArray,
              participant_ids: newparticipantId,
            };
            sendInvite(newMembersArray);
            
          }
        })
        .catch((error: any) => {
          setShowLoader(false);
          toast.error(error.response.data.message);
          setShowLoader(false);
        });
    }
  };

  const addTeamMember = () => {
    let team_member = {
      account_id: props.account_id,
      name: newMemberData.name,
      email: newMemberData.email.toLowerCase(),
    };
    
    addNewMembersApi(team_member)
      .then((response: any) => {
        toast.success("New team member added");
        teamMembersData();
        setNewMemberData({
          name: "",
          email: ""
        });
      })
      .catch((err: any) => {
        toast.error(
          "Couldn't add new team members: " +
            err.response.data.message
        );
      });
  };

  const sendInvite = (newaddedMembersListArray: any) => {
    inviteTeamMembersApi(newaddedMembersListArray,null)
      .then((response: any) => {
        setShowLoader(true);
        toast.success(response.data.message);
        navigate(-1);
        props.handleClose();
        if (response.data.message === "Invitations Sent") {
          setTimeout(() => {
            window.location.reload();
            setShowLoader(false);
          }, 1000);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
        toast.error(error.response.data.message);
        setShowLoader(false);
      });
  };

  const hiddenFileInput = useRef<any>();
  const handleFileChange = (event: any) => {
    event.preventDefault();
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  const { readString } = usePapaParse();
  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    if (
      e.target.files[0].type === "text/csv" ||
      e.target.files[0].type === ".csv"
    ) {
      handleOnSubmit(e);
    } else {
      toast.error("File not Supported !");
    }
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (e.target.files[0]) {
      fileReader.onload = function (e: any) {
        const csvOutput = e.target?.result;
        readString(csvOutput, {
          worker: true,
          complete: (results: any) => {
            setShowLoader(true);
            let noOfMembersInCSV = 0;
            let counter = 0;

            let member = [...membersListArray];
            for (let [index, d] of results.data.entries()) {
              if (index !== 0) {
                //  -- Line 137-142 (:headers accepted in csv file)
                let team_member = {
                  account_id: props.account_id,
                  name: d[0],
                  email: d[1],
                };

                // if the name or email field is empty or invalid, skip this member
                if (
                  team_member.name === "" ||
                  team_member.email === ""
                ) {
                  toast.error(
                    "excluded - " +
                      team_member.name +
                      " due to invalid email or name"
                  );
                  continue;
                }

                noOfMembersInCSV = noOfMembersInCSV + 1;
                setShowLoader(true);
                addNewMembersApi(team_member)
                  .then((response: any) => {
                    setShowLoader(false);
                    counter = counter + 1;
                    teamMembersData();
                    if (counter === noOfMembersInCSV) {
                      toast.success(response.statusText);
                    }
                    teamMembersData();
                  })
                  .catch((err: any) => {
                    setShowLoader(false);
                    toast.error(
                      "excluded - " +
                        team_member.name +
                        " due to" +
                        err.response.data.message
                    );
                  });
              }

              // let team_member = { account_id: props.account_id, name: d[1], email: d[2] };           //  -- Line 137-142 (:no headers accepted in csv file)
              //   setShowLoader(true);
              //   addNewMembersApi(team_member)
              //     .then((response: any) => {
              //       setShowLoader(false);
              //       toast.success(response.statusText);
              //     })
              //     .catch((err: any) => {
              //       setShowLoader(false);
              //       toast.error(err.response.data.message);
              //     });
            }
            setMembersListArray(member);
          },
        });
      };

      fileReader.readAsText(e.target.files[0]);
    } else {
      toast.error("No file selected !");
    }
  };

  return (
    <div className="mx-5 ">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      <div className=" py-2 md:py-5 overflow-hidden">
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Invite Team Members
            </h3>
            <div className="mt-1 pb-1 md:pb-0">
              <p className="text-sm">
                You can manage your account's team members here, and add them to this Flow Foundation as survey participants.
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          {showLoader === true && (
            <div className="h-full grid place-content-center my-5">
              <TailSpin color="#019393" height={30} width={30} />
            </div>
          )}
          {showLoader === false && (
            <div className="border-b border-gray-200 ">
              <ul
                role="list"
                className="divide-y divide-gray-200 overflow-y-auto h-[40vh] md:px-5"
              >
                {membersListArray.map((listOfMembers: any, i: any) => (
                  <li className="py-4 flex relative border-b-2 border-gray-100" key={i}>
                    
                    <div className=" w-40 sm:w-full flex flex-col">
                      <span className="text-sm font-medium text-gray-900">
                        {listOfMembers.name}
                      </span>
                      <span className="text-sm text-gray-500 truncate">
                        {listOfMembers.email}
                      </span>
                    </div>
                    {listOfMembers.isAdded ? (
                      <button
                        type="button"
                        onClick={() => removeMember(listOfMembers.id)}
                        className="absolute items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5] top-[20px] right-[20px]"
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => addedMembers(listOfMembers.id)}
                        className="absolute items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5] top-[20px] right-[20px]"
                      >
                        Add
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="space-y-1">
            <p id="add-team-members-helper-name" className="sr-only">
              New team member's name
            </p>
            <p id="add-team-members-helper-email" className="sr-only">
              New team member's email address
            </p>
            <div className="flex">
              <div className="flex-grow">
                <input
                  type="text"
                  name="add-team-members-name"
                  id="add-team-members=name"
                  className="inline h-10 px-2 w-auto shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Name"
                  aria-describedby="add-team-members-helper-name"
                  value={newMemberData.name}
                  onChange={(e) => setNewMemberData({ ...newMemberData, name: e.target.value })}
                />
                <input
                  type="email"
                  name="add-team-members"
                  id="add-team-members"
                  className="inline h-10 px-2 w-auto shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Email"
                  aria-describedby="add-team-members-helper"
                  value={newMemberData.email}
                  onChange={(e) => setNewMemberData({ ...newMemberData, email: e.target.value })}
                />
              </div>
              <span className="ml-3">
                <button
                  type="button"
                  onClick={addTeamMember}
                  className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  <svg
                    className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                    x-description="Heroicon name: solid/plus"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"></path>
                  </svg>
                  <span>Add to team</span>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            onClick={() => {
              props.handleClose();
            }}
            className="mt-3 w-full inline-flex justify-center my-1 md:my-0 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5] sm:mt-0 sm:col-start-1 sm:text-sm"
          >
            Close
          </button>
          <button
            onClick={handleFileChange}
            className="w-full  rounded-md cursor-pointer border border-transparent shadow-sm px-4 py-2 bg-[#00B5B5] text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5] sm:col-start-2 sm:text-sm"
          >
            Upload CSV
          </button>
          <input
            type={"file"}
            id={"csvFileInput"}
            ref={hiddenFileInput}
            accept={".csv,text/csv"}
            style={{ display: "none" }}
            onChange={handleOnChange}
            className="ml-5 bg-white py-2 w-full px-3 border my-1 md:my-0 border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5]"
          />
        </div>
      </div>
    </div>
  );
};
export default FlowFoundationInvite;
