import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Signup from "./components/signup";
import LinearProgress from "@mui/material/LinearProgress";
import FlowFoundationView from "./components/flow-foundation-view";
import ProtectedOutlet from "./components/auth/protected-outlet";

const BasicCard = lazy(() => import("./components/dashboard"));
const FlowFoundation = lazy(() => import("./components/flow-foundation-app"));
const ForgotPassword = lazy(() => import("./components/forgot-password"));
const HomePage = lazy(() => import("./components/homepage"));
const LessonsPage = lazy(() => import("./components/lessons-page"));
const AppPage = lazy(() => import("./components/app-page"));
const InactiveAppForm = lazy(() => import("./components/inactive-app-page"));
const UserProfile = lazy(() => import("./components/manage-profile"));
const Settings = lazy(() => import("./components/settings"));
const Help = lazy(() => import("./components/help"));
const Support = lazy(() => import("./components/support"));
const ResultsPage = lazy(() => import("./components/app-in-progress"));
const DetailedGraphPage = lazy(
  () => import("./components/detailed-graph-view")
);
const QustionaireHomepage = lazy(
  () => import("./components/questionaire-homepage")
);
const CreateFlow = lazy(() => import("./components/create-flow"));
const Questions = lazy(() => import("./components/questions-page"));
const AppCourse = lazy(() => import("./components/app-course"));
const ProgressData = lazy(
  () => import("./components/flow-foundation-progress-data")
);

const LocalRoutes = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="signup" element={<Signup />} />
        <Route path="/*" element={<ProtectedOutlet />}>
          <Route path="journey-brain" element={<HomePage />}>
            <Route path="dashboard" element={<BasicCard />} />
            <Route path="flow-foundation" element={<FlowFoundation />}>
              <Route path="new" element={<FlowFoundationView />} />
              <Route path="in-progress" element={<FlowFoundationView />} />
              <Route
                path="in-progress-view-chart/:assessmentId/:assessmentTypeId" element={<DetailedGraphPage />}
              />
              <Route path="in-progress-results" element={<ResultsPage />} />
              <Route path="progress-data" element={<ProgressData />} />
            </Route>
            <Route path="app-page" element={<AppPage />} />
            <Route path="lessons-page/:lessonId" element={<LessonsPage />} />
            <Route path="manage-profile" element={<UserProfile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="help" element={<Help />} />
            <Route path="support" element={<Support />} />
            <Route path="register-interest" element={<InactiveAppForm />} />
            
            <Route path="create-flow" element={<CreateFlow />} />
            <Route path="app-course" element={<AppCourse />} />
          </Route>
        </Route>
        <Route path="questionaire-homepage" element={<QustionaireHomepage />}
            />
        <Route path="assessments" element={<Questions />} />
      </Routes>
    </Suspense>
  );
};
export default LocalRoutes;
