import { useLocation } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";

import { FileUploader } from "react-drag-drop-files";

import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

import { AxiosResponse } from "axios";
import dayjs from "dayjs";

import {
  getAssessmentDetailsApi,
  saveAssessmentDetailsApi,
  upload,
} from "../services/http-api-service";

import "react-toastify/dist/ReactToastify.css";

import { CKEditor } from "ckeditor4-react";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export interface FlowFoundationEditPropTypes {
  setAssessment: (value: any) => void;
}

interface DragDropProps {
  handleSetVideoUrl: (value: string) => any;
}

const fileTypes = ["MP4"];

const DragDrop: React.FC<DragDropProps> = ({ handleSetVideoUrl }) => {
  const uploadFile = async (newFile: any) => {
    const FD: FormData = new FormData();

    FD.set("file_to_upload", newFile);
    FD.set("folder", "videos");

    try {
      const res: AxiosResponse = await upload(FD);
      const key: string = res.data?.key;

      handleSetVideoUrl(key);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const handleChange = (file: any) => {
    if(file.size > 20971520 ){
      toast.error("Video is bigger more then 20MB. Please upload another video.");   
      const listItem = document.querySelector("#dropdiv label div span");
      const newItem = document.createElement('span');
      newItem.innerHTML = '<span style="color:red; text-decoration: underline;">Not uploaded due to big video!.</span> Upload another?';
      listItem?.parentNode?.replaceChild(newItem, listItem);
      return false;
    }else{
      uploadFile(file);
      const listItem = document.querySelector("#dropdiv label div span");
      const newItem = document.createElement('span');
      newItem.innerHTML = '<span style="text-decoration: underline;">Uploaded sucessfuly!.</span> Upload another?';
      listItem?.parentNode?.replaceChild(newItem, listItem);
    }
  };

  return <FileUploader handleChange={handleChange} name="file" types={fileTypes} />;
};

const FlowFoundationEdit = ({ setAssessment }: FlowFoundationEditPropTypes) => {
  const { state } = useLocation() as any;
  const [showLoader, setShowLoader] = useState(true);
  const [checking, setChecking] = useState(true);
  const [assessmentDetails, setAssessmentDetails] = useState<Record<string, any>>({
    id: null,
    application_id: null,
    account_id: null,
    name: "",
    description: "",
    video_url: "",
    temp_url: "",
    due_date: "",
    status: "",
    reminder_text: "",
    intro_text: "",
    created_at: "2022-06-28T14:47:25.000000Z",
    updated_at: "2022-06-28T15:20:57.000000Z",
    deleted_at: null,
  });

  useEffect(() => {
    if (assessmentDetails.name && assessmentDetails?.description && assessmentDetails.due_date) {
      setChecking(false);
    } else {
      setChecking(true);
    }
  }, [assessmentDetails]);

  useEffect(() => {
    return () => AssessmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AssessmentData = () => {
    getAssessmentDetailsApi(state.assessmentData.id)
      .then((response: any) => {
        let data = response.data.data;
        setAssessmentDetails(response.data.data);
        setAssessment(() => ({ ...data }));
        setShowLoader(false);
      })
      .catch((err: any) => console.log(err));
  };

  function saveAssessmentData() {
    setShowLoader(true);

    saveAssessmentDetailsApi(assessmentDetails)
      .then((response: any) => {
        AssessmentData();
        toast.success(response.statusText);
        setShowLoader(false);
      })
      .catch((error: any) => {
        toast.error(error.response.data.message);
        setShowLoader(false);
      });
  }

  const handleSetVideoUrl = (videoUrl: any) => {
    setAssessmentDetails({ ...assessmentDetails, video_url: videoUrl });
  };

  return (
    <div className="">
      {showLoader === true && (
        <div className="h-screen grid place-items-center">
          <TailSpin color="#019393" height={80} width={80} />
        </div>
      )}

      {showLoader === false && (
        <div className="flex h-full flex-col bg-white ">
          <div className="bg-[#00B5B5] py-6 px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <h2 className="sm:text-lg text-2xl  font-medium text-white" id="slide-over-title">
                Edit Flow Foundation
              </h2>
            </div>
            <div className="mt-1">
              <p className="text-sm text-white">Edit your Flow Foundation here</p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between overflow-y-auto">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pt-6 pb-5">
                <div>
                  <label className="block text-xl md:text-md lg:text-sm font-medium  text-gray-900">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="project-name"
                      id="project-name"
                      value={assessmentDetails.name}
                      onChange={(e) => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          name: e.target.value,
                        });
                      }}
                      className="block px-2 py-2  w-full rounded-md border-gray-300 shadow-sm h-12 border focus:outline-none focus:z-10 focus:border-[#00B5B5] focus:ring-[#00B5B5] text-xl md:text-md lg:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label className="block mt-10 lg:mt-2 text-xl md:text-md lg:text-sm font-medium text-gray-900">
                    Description
                  </label>
                  <div className="mt-1">
                    <CKEditor
                      initData={assessmentDetails.description}
                      onChange={(e) => {
                        setAssessmentDetails((state) => ({
                          ...state,
                          description: e.editor.getData(),
                        }));
                      }}
                      type={"inline"}
                    />
                    {/* <textarea
                      id="description"
                      name="description"
                      value={assessmentDetails.description}
                      onChange={(e) => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          description: e.target.value,
                        });
                      }}
                      className="block p-2 w-full rounded-md h-24 border focus:outline-none focus:z-10 border-gray-300 shadow-sm focus:border-[#00B5B5] focus:ring-[#00B5B5] text-xl md:text-md lg:text-sm"
                    ></textarea> */}
                  </div>
                </div>
                <div>
                  <label className="block text-xl md:text-md lg:text-sm font-medium text-gray-900">
                    Explanation Video
                  </label>
                  <div className="space-y-1 text-center dropdiv" id="dropdiv">
                    <DragDrop handleSetVideoUrl={handleSetVideoUrl} />
                    <p className="text-xs text-gray-500">MP4 up to 20MB</p>
                  </div>
                  <p className="mt-2 text-lg md:text-sm lg:text-xs text-gray-500">
                    If a video isn't uploaded, a default video from Watertight Marketing will be
                    used.
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-900">Due Date :</label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={dayjs(assessmentDetails.due_date).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setAssessmentDetails({
                        ...assessmentDetails,
                        due_date: e.target.value,
                      });
                    }}
                    className="mt-1 px-2 focus:ring-[#00B5B5] h-10 px-2 border focus:outline-none focus:z-10 focus:border-[#00B5B5] block w-full shadow-sm text-xl md:text-md lg:text-sm border-gray-300 rounded-md"
                  />
                  <p className="my-10 lg:mt-2 lg:my-0 text-xl md:text-md lg:text-sm text-gray-500">
                    This is required. Participants will receive email reminders to complete the
                    survey when we come close to this date.
                  </p>
                </div>
                <div>
                  <label className="block text-xl md:text-md lg:text-sm font-medium  text-gray-900">
                    Custom reminder text
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="reminder-text"
                      id="reminder-text"
                      value={assessmentDetails.reminder_text}
                      onChange={(e) => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          reminder_text: e.target.value,
                        });
                      }}
                      className="block px-2 py-2  w-full rounded-md border-gray-300 shadow-sm h-12 border focus:outline-none focus:z-10 focus:border-[#00B5B5] focus:ring-[#00B5B5] text-xl md:text-md lg:text-sm"
                    />
                  </div>
                </div>

                {!checking ? (
                  <button
                    onClick={saveAssessmentData}
                    className="inline-flex justify-center py-2  w-full lg:w-20 sm:px-4 border border-transparent shadow-sm text-xl md:text-md lg:text-sm font-medium rounded-md text-white bg-[#00B5B5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5]"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    disabled={checking}
                    className="inline-flex justify-center py-2 w-full lg:w-20 sm:px-4 border border-transparent shadow-sm text-xl md:text-md lg:text-sm font-medium rounded-md text-white bg-gray-200 hover:bg-gray-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00B5B5]"
                  >
                    Save
                  </button>
                )}
              </div>
              <div className="pt-4 pb-6">
                <div className="mt-4 flex text-sm">
                  <a
                    href="/journey-brain/app-course"
                    className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                  >
                    <svg
                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      x-description="Heroicon name: solid/question-mark-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-2">Need help? Try our Flow Foundations course</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlowFoundationEdit;
