
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { usePapaParse } from "react-papaparse";
import { participantsListApi, sendSurveyApi, getAssessmentDetailsApi } from '../services/http-api-service';
import { useLocation, useNavigate } from "react-router-dom";

interface propsInterface {
  handleClose: any;
  id: number;
}
const SendSurvey: React.FC<propsInterface> = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation() as any;
    const [assessmentDetails, setAssessmentDetails] = useState<Record<string, any> | null>(null);
  
    const setAssessment = (data: Record<string, any>) => setAssessmentDetails(data);
  
    const getAssessmentData = () => {
      getAssessmentDetailsApi(state.assessmentData.id)
        .then((res: any) => {
          let data = res.data.data;
          setAssessmentDetails(data);
        })
        .catch((err: any) => console.log(err));
    };
  
    useEffect(() => {
      return () => getAssessmentData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const sendSurvey = () => {
        participantsListApi(props.id)
          .then((response: any) => {
            let participantsList = response.data.data;
            const participant_ids : string[] = [];
            
            for (let participant of participantsList) {
              participant_ids.push(participant.id);
            }
            
            let data = {
              assessment_id: props.id,
              participant_ids: participant_ids
            };
            
            sendSurveyApi(data)
              .then((response: any) => {
                toast.success(response.data.message);
                
                navigate("/journey-brain/flow-foundation/in-progress", {
                  state: { routeState: state, assessmentData: assessmentDetails },
                });
                
                props.handleClose();
              })
              .catch((error: any) => {
                toast.error(error.response.data.message);
              });
          });
      };
    
  return (
    <div className="mx-5 ">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
     <div className="relative z-10">
      
      <div  className=""></div>
    

    <div  className="">
      <div  className="">
        
          <div className="py-5 px-3" >
            <div  className="sm:flex sm:items-start">
              <div  className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-[#FFEDD5] sm:mx-0 sm:h-10 sm:w-10">
                <svg  className="h-6 w-6 text-orange-600" x-description="Heroicon name: outline/exclamation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" aria-hidden="true">
<path  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
</svg>
              </div>
              <div  className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3  className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  This will send the Flow Foundations survey to all participants
                </h3>
                <div  className="mt-2">
                  <p  className="text-sm text-gray-500">
                    Please make sure that you've finished inviting team members and adding them to this Flow Foundation. Then click "Send Survey" below.
                  </p>
                  <p  className="text-sm text-gray-500">
                    Once the survey is sent it can't be sent again unless you restart this Flow Foundation.
                  </p>
                </div>
              </div>
            </div>
            <div  className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button onClick={sendSurvey} 
               className="w-full inline-flex justify-center bg-[#EA580C] rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#EA580J] text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm" >
                Send Survey
              </button>
              <button onClick={() => {
              props.handleClose();
            }}  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </div>
          </div>
        
      </div>
    </div>
  </div>
</div>
  );
};
export default SendSurvey;
